import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { MultiSelect } from "primereact/multiselect";
import Moment from "moment";

import { addDateTime } from "../../../../common/date-time";
import {
    getCCIncidentUsecasesInit,
    getCCIncidentUsecasesLogsInit,
} from "../../redux/actions";
import {
    alertPayloadTemplate,
    changeRequestTemplate,
    commonTableFilters,
    modifiedAtTemplate,
    receivedAtTemplate,
} from "../utils";
import SelectedChips from "../../../../common/components/selected-chips/SelectedChips";
import DetailSummaryDialog from "./DetailSummaryDialog";
import "./IncidentSummary.scss";

/**
 * Functional Component to show Incident Logs
 * @returns JSX
 */
const IncidentSummary = () => {
    const usecaseList = useSelector(
        (state) => state.commandCenter.incidentUsecases
    );
    const incidentLogs = useSelector(
        (state) => state.commandCenter.incidentUsecasesLogs
    );

    const totalRecords = useSelector(
        (state) => state.commandCenter.totalRecord
    );
    const dispatch = useDispatch();
    const [selectedUsecases, setSelectedUsecases] = useState([]);
    const [dateRange, setDateRange] = useState([
        addDateTime("day", -14),
        addDateTime("day", 0),
    ]);
    const [tableFilters, setTableFilters] = useState(null);
    const [dialogData, setDialogData] = useState(null);
    const logsTableRef = useRef(null);
    const toastRef = useRef(null);
    const [filterCondition, setFilterCondition] = useState(""); // **Added filterCondition state**


    const [lazyParams, setLazyParams] = useState({
        first: 0, // Starting index
        rows: 10, // Default page size
        page: 0, // Current page number
        sortField: "received_date", // Default sort field
        sortOrder: -1, // Default sort order (-1 for descending, 1 for ascending)
    });


    /**
     * Fetch usecases list and initialize table filter once Component is mounted
     */
    useEffect(() => {
        dispatch(getCCIncidentUsecasesInit());
        initializeTableFilter();
    }, []);

    /**
     * Marking all usecases as selected once loaded
     */
    useEffect(() => {
        setSelectedUsecases([...usecaseList]);
        if (usecaseList.length > 0) {
            fetchLogs(true);
        }
    }, [usecaseList]);

    /**
     * Function to initialize Table filter
     */
    const initializeTableFilter = () => {
        setTableFilters({
            ...commonTableFilters,
            incident_number: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
        });
    };

    /**
     * Function to fetch logs based on selected usecase and datetime
     * @param {boolean} initialLoad - Flag to define whether the function is called at the time of loading usecase list
     */
    const fetchLogs = (initialLoad,params=lazyParams,currentFilters=filterCondition) => {
        const sortDirection= params.sortOrder== -1?'DESC' : 'ASC';
        let usecaseType = selectedUsecases.join(",");
        if (selectedUsecases.length === 0 && initialLoad !== true) {
            toastRef.current.show({ severity: 'error', summary: 'Error', detail: 'No App Selected', life: 2000 });
            return;
        }
       else if (initialLoad === true) {
            usecaseType = usecaseList.join(",");
        }
        const startDate = Moment(dateRange[0]).format("yyyy-MM-DD");
        const endDate = !!dateRange[1]
            ? Moment(dateRange[1]).format("yyyy-MM-DD")
            : Moment(dateRange[0]).format("yyyy-MM-DD");
        const offset = Moment(new Date()).utcOffset();
        const filterConditionPayload = constructFilterParams(currentFilters);
        const requestParams={
            usecase: usecaseType,
            startDate,
            endDate,
            offset,
            pageNumber: params.page ?? 0, // Current page number
            pageSize: params.rows ?? 10, // Number of rows per page
            sortBy: params.sortField ?? "received_date", // Sorting field
            sortDirection: sortDirection ?? "DESC", // Sorting order
            filterCondition: filterConditionPayload ?? "" // **Filter condition**
        };
        dispatch(
                getCCIncidentUsecasesLogsInit(
                    requestParams
                )
            );
    };

    const constructFilterParams = (filterConditions) => {
        if (!filterConditions || typeof filterConditions !== "object") {
          return ""; // Return empty string if no filters are present
        }
      
        const queryParams = [];
      
        Object.keys(filterConditions).forEach((key) => {
          const filter = filterConditions[key];
          const constraints = filter?.constraints;
      
          if (constraints && constraints.length > 0) {
            const { value, matchMode } = constraints[0];
      
            if (value !== undefined && value !== null && value !== "") {
              queryParams.push(
                `${encodeURIComponent(key)}&${encodeURIComponent(matchMode)}&${encodeURIComponent(value)}`
              );
            }
          }
        });
      
        return queryParams.join(",");
      };

    /**
     * Function to provide template for Details column in datatable
     * @param raw column
     * @returns templated column
     */
    const detailTemplate = ({ incident_number }) => (
        <i
            className="pi pi-info-circle"
            onClick={() => setDialogData(incident_number)}
        ></i>
    );
 /**
   * Function to export table data
   */
 const exportIncidentLogs = () => logsTableRef.current.exportCSV();

  /**
     * Handle filtering event
     */
  const onFilter = (filters) => {
    console.log("filters",filters);
    setFilterCondition(filters);
    const updatedParams={
        ...lazyParams, 
          first: 0,
          page: 0
    };
    setLazyParams(updatedParams); // Reset to first page
    fetchLogs(false,updatedParams,filters); // Fetch filtered data
};

/**
 * Handle pagination event
 */
const onPage = (event) => {
    console.log( "pageevnt",event);
    const updatedParams=
    {
        ...lazyParams,
        first: event.first, // Start index
        rows: event.rows, // Rows per page
        page: event.page // Current page number
    };
    setLazyParams(updatedParams);
    console.log( "page",lazyParams.page, // Current page number
        lazyParams.rows, // Number of rows per page
        lazyParams.sortField)
        fetchLogs(false,updatedParams); // Fetch data for the new page
};

/**
 * Handle sorting event
 */
const onSort = (event) => {
   const updatedParams={
    ...lazyParams,
    sortField: event.sortField, // Sorting field
    sortOrder: event.sortOrder // Sorting order
};
    setLazyParams(updatedParams);
    fetchLogs(false,updatedParams); // Fetch sorted data
};

    return (
        <div className="incident-summary-container">
            <DetailSummaryDialog
                dialogData={dialogData}
                setDialogData={setDialogData}
            />
            <div className="row summary-action">
                <div className="col-12 col-lg-6 input-field">
                    <label htmlFor="usecase">Process Type</label>
                    <MultiSelect
                        id="usecase"
                        value={selectedUsecases}
                        options={usecaseList}
                        onChange={(e) => setSelectedUsecases(e.value)}
                        filter
                    />
                </div>
                <div className="col-12 col-lg-6 input-field">
                    <label htmlFor="dateRange">Events Date</label>
                    <Calendar
                        id="dateRange"
                        value={dateRange}
                        onChange={(e) => setDateRange(e.value)}
                        selectionMode="range"
                        minDate={addDateTime("month", -3)}
                        maxDate={addDateTime("month", 0)}
                        showIcon
                    />
                </div>
                <div className="col-12">
                    <SelectedChips
                        label="Selected Process Type"
                        selectedValues={selectedUsecases}
                        setSelectedValues={setSelectedUsecases}
                    />
                </div>
                <div className="col-12">
                    <Button
                        label="Get Logs"
                        onClick={fetchLogs}
                        disabled={selectedUsecases.length === 0}
                    />
                    <Button 
                        icon="pi pi-download"
                        className="p-button-text"
                        onClick={exportIncidentLogs}
                        style={{float: "right"}}
                    />
                    <Button 
                        id='refreshBtn'
                        icon="pi pi-refresh"
                        className="p-button-text"
                        onClick={fetchLogs}                       
                        style={{float: "right"}}
                    />
                </div>
            </div>
            <DataTable
                value={incidentLogs}
                ref={logsTableRef}
                filters={tableFilters}
                filterDisplay="menu"
                onFilter={(e) => onFilter(e.filters)} // **Handle filtering event**
                sortField={lazyParams.sortField} // Dynamic sort field
                sortOrder={lazyParams.sortOrder} // Dynamic sort order
                lazy
                paginator
                totalRecords={totalRecords} // Dynamic total records from Redux
                first={lazyParams.first} // Start index
                rows={lazyParams.rows} // Rows per page
                rowsPerPageOptions={[10, 20, 50]} // Page size options
                onPage={onPage} // Pagination handler
                onSort={onSort} // Sorting handler
                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                emptyMessage="No logs found."
            >
                <Column
                    header="Incident"
                    field="incident_number"
                    sortable
                    filter
                />
                <Column header="Process Type" field="usecase" sortable filter />
                <Column
                    header="Received At"
                    field="received_date"
                    body={receivedAtTemplate}
                    sortable
                />
                <Column header="Status" field="status" sortable filter />
                <Column
                    header="Description"
                    field="description"
                    sortable
                    filter
                />
                <Column
                    header="Change Request"
                    field="cr_number"
                    body={changeRequestTemplate}
                    sortable
                    filter
                />
                <Column
                    header="Retry Count"
                    field="retry_count"
                    sortable
                    filter
                />
                <Column
                    header="Modified At"
                    field="modified_date"
                    body={modifiedAtTemplate}
                    sortable
                />
                <Column
                    header="Alert Payload"
                    field="alert_payload"
                    body={alertPayloadTemplate}
                />
                <Column
                    header="ITSM Retry Count"
                    field="itsm_retry_count"
                    sortable
                />
                <Column header="Details" body={detailTemplate} />
            </DataTable>
        </div>
    );
};

export default IncidentSummary;
