import { GET_ALERT_SUPPRESSION_REASONS,
POST_ALERT_SUPPRESSION_INFORMATION,
GET_ALERT_SUPPRESSION_LOGS,
DELETE_ALERT_SUPPRESSION_REQUEST } from "./actionTypes";

const initialState = {
    alertSuppressionReasons: [],
    alertSuppressionReasonErrorMessage: "",
    alertSuppressionLogs: [],
    alertSuppressionLogsErrorMessage: "",
    alertSuppressionInfoSuccessMessage: "",
    alertSuppressionInfoErrorMessage: "",
    deleteAlertSuppressionRequestSuccessMessage: "",
    delteAlertSuppressionRequestErrorMessage: ""
}

const reseAlertSuppressionReducer = (state = initialState, action = {type: "", payload:null}) => {
    switch(action.type) {
        case GET_ALERT_SUPPRESSION_REASONS.SUCCESS:
            return {
                ...state,
                alertSuppressionReasons: action.payload
            };
        case GET_ALERT_SUPPRESSION_REASONS.ERROR:
            return {
                ...state,
                alertSuppressionReasonErrorMessage: action.payload
            };
        case POST_ALERT_SUPPRESSION_INFORMATION.SUCCESS:
            return {
                ...state,
                alertSuppressionInfoSuccessMessage: action.payload
            };
        case POST_ALERT_SUPPRESSION_INFORMATION.ERROR:
            return {
                ...state,
                alertSuppressionInfoErrorMessage: action.payload
            };
        case GET_ALERT_SUPPRESSION_LOGS.SUCCESS:
            return {
                ...state,
                alertSuppressionLogs: action.payload
            };
        case GET_ALERT_SUPPRESSION_LOGS.ERROR:
            return {
                ...state,
                alertSuppressionLogsErrorMessage: action.payload
            };
        case DELETE_ALERT_SUPPRESSION_REQUEST.SUCCESS:
            return {
                ...state,
                deleteAlertSuppressionRequestSuccessMessage: action.payload
            };
        case DELETE_ALERT_SUPPRESSION_REQUEST.ERROR:
            return {
                ...state,
                deleteAlertSuppressionRequestErrorMessage: action.payload
            };
        default:
            return state;
    }
}
export default reseAlertSuppressionReducer;