import axios from "axios";
import { ApiPath } from "../../../common/api-path/ApiPath";

export const getAlertSuppressionReasons = async () => {
    const response = await axios.get(`${ApiPath.baseUrl}/getAlertSuppressionReasonDetails`, { timeout: 120000, timeoutErrorMessage: "request timeout" });
    if (response.status >= 400) {
        throw new Error(response.error);
    }
    return response.data;
};

export const postAlertSuppressionInformation = async (alertInfo) => {
    const response = await axios.post(`${ApiPath.baseUrl}/addAlertSuppressionDetails`, alertInfo, { timeout: 120000, timeoutErrorMessage: "request timeout" });
    if (response.status >= 400) {
        throw new Error(response.error);
    }
    return response.data;
}

export const getAlertSuppressionLogs = async (startDatetime, endDatetime, offset) => {
    const requestConfig = {
        params: {startDatetime, endDatetime, offset},
        timeout: 120000,
        timeoutErrorMessage: "request timeout"
    }
    const response = await axios.get (`${ApiPath.baseUrl}/getAlertSuppressionDetails`, requestConfig);
    if (response.status >= 400) {
        throw new Error(response.error);
    }
    return response.data;
}

export const deleteAlertSuppressionRequest = async (suppressionId, workStatus) => {
    const requestConfig = {
        params: {suppressionId, workStatus},
        timeout: 120000,
        timeoutErrorMessage: "request timeout"
    }
    const response = await axios.delete(`${ApiPath.baseUrl}/deleteAlertSuppressionDetails`, requestConfig);
    if (response.status >= 400) {
        throw new Error(response.error);
    }
    return response.data;
}