import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';

import { parseDateTime } from '../../../../common/date-time';

const ReseAlertSuppressionLogsDialog = ({dialogData, setDialogData}) => {

    const createdAt = !!dialogData && parseDateTime(dialogData.created_timestamp)
    const modifiedAt = !!dialogData && dialogData.modified_timestamp ? parseDateTime(dialogData.modified_timestamp) : "NA";
    const modifiedBy = !!dialogData && dialogData.modified_by ? dialogData.modified_by : "NA";
    const workStatus = !!dialogData && dialogData.work_status ? dialogData.work_status : "NA";
    return (
        <div>
            <Dialog
            visible={!!dialogData}
            onHide={() => setDialogData(null)}
            header={`Suppression ID: ${dialogData?.suppression_id}`}
            style={{ width: "40vw" }}
            >
                <div>
                    <p>
                        <span style={{ fontWeight: 700 }}> Created By : </span>
                        {dialogData?.created_by}
                    </p>
                    <Divider />
                    <p>
                        <span style={{ fontWeight: 700 }}> Created At : </span>
                        {createdAt}
                    </p>
                    <Divider />
                    <p>
                        <span style={{ fontWeight: 700 }}> Modified By : </span>
                        {modifiedBy}
                    </p>
                    <Divider />
                    <p>
                        <span style={{ fontWeight: 700 }}> Modified At : </span>
                        {modifiedAt}
                    </p>
                    <Divider />
                    <p>
                        <span style={{ fontWeight: 700 }}> Work Status : </span>
                        {workStatus}
                    </p>
                    <Divider />
                </div>

            </Dialog>
        </div>
    );
}

export default ReseAlertSuppressionLogsDialog;