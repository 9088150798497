import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { MultiSelect } from "primereact/multiselect";
import Moment from "moment";
import { parseDateTime, addDateTime } from "../../common/date-time";
import SelectedChips from "../../common/components/selected-chips/SelectedChips";
import { getRpaUsecasesInit, getRpaSummaryLogsInit } from "./redux/actions";
import AutomationLogsDetailDialog from "./AutomationLogsDetailDialog";
import CopyButton from "../../common/components/copy-button/CopyButton";

import "./AutomationLogs.scss";

const AutomationLogs = () => {
  const usecases = useSelector((state) => state.rpa.rpaUsecases);
  const summaryLogs = useSelector((state) => state.rpa.summaryLogs);
  const dispatch = useDispatch();
  const [selectedUsecases, setSelectedUsecases] = useState([]);
  const [dateRange, setDateRange] = useState([
    addDateTime("day", -14),
    addDateTime("day", 0),
  ]);
  const [tableFilters, setTableFilters] = useState(null);
  const [dialogData, setDialogData] = useState(null);
  const logsTableRef = useRef(null);


  useEffect(() => {
    dispatch(getRpaUsecasesInit());
    initializeTableFilter();
  }, []);

  useEffect(() => {
    setSelectedUsecases([...usecases]);
    if (usecases.length > 0) {
      fetchSummaryLogs(true);
    }
  }, [usecases]);

  const fetchSummaryLogs = (initialLoad) => {
    let usecaseType = selectedUsecases.join(",");
    if (initialLoad === true) {
      usecaseType = usecases.join(",");
    }
    const startDate = Moment(dateRange[0]).format("yyyy-MM-DD");
    const endDate = !!dateRange[1]
      ? Moment(dateRange[1]).format("yyyy-MM-DD")
      : Moment(dateRange[0]).format("yyyy-MM-DD");
    const offset = Moment(new Date()).utcOffset();
    dispatch(getRpaSummaryLogsInit(usecaseType, startDate, endDate, offset));
  };

  const initializeTableFilter = () => {
    setTableFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      ctaskId: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      status: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      pushRetryCount: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      usecaseType: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      ctaskRequester: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  const receivedAtTemplate = ({ receivedDate }) =>
    !!receivedDate ? (
      parseDateTime(receivedDate)
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );

  const scheduledAtTemplate = ({ scheduledTime }) =>
    !!scheduledTime ? (
      parseDateTime(scheduledTime + "+00")
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );

  const modifiedAtTemplate = ({ modifiedDate }) =>
    !!modifiedDate ? (
      parseDateTime(modifiedDate)
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );

  const inputPayloadTemplate = ({ inputPayload }) => (
    <div className="payload-template">
      <CopyButton className="copy-icon" textToCopy={inputPayload} />
      <br />
      {inputPayload}
    </div>
  );

  const detailTemplate = ({ ctaskId, usecaseType }) => (
    <i
      className="pi pi-eye"
      onClick={() => setDialogData({ ctaskId, usecaseType })}
    />
  );

    /**
   * Function to export table data
   */
const exportSummaryLogs = () => logsTableRef.current.exportCSV();

  return (
    <div className="automation-logs-container container-fluid">
      <p className="page-label">
        Automation Logs
        <i className="pi pi-angle-right" />
        Voice Automation
      </p>
      <AutomationLogsDetailDialog
        dialogData={dialogData}
        setDialogData={setDialogData}
      />
      <div className="automation-logs-action row">
        <div className="col-12 col-lg-6 input-field">
          <label htmlFor="usecase">Process Type</label>
          <MultiSelect
            id="usecase"
            value={selectedUsecases}
            options={usecases}
            onChange={(e) => setSelectedUsecases(e.value)}
            filter
          />
        </div>
        <div className="col-12 col-lg-6 input-field">
          <label htmlFor="dateRange">Events Date</label>
          <Calendar
            id="dateRange"
            value={dateRange}
            onChange={(e) => setDateRange(e.value)}
            selectionMode="range"
            minDate={addDateTime("month", -3)}
            maxDate={addDateTime("month", 0)}
            showIcon
          />
        </div>
        <div className="col-12">
          <SelectedChips
            label="Selected Process Type"
            selectedValues={selectedUsecases}
            setSelectedValues={setSelectedUsecases}
          />
        </div>
        <div className="col-12">
          <Button
            label="Get Logs"
            onClick={fetchSummaryLogs}
            disabled={selectedUsecases.length === 0}
          />
          <Button 
            icon="pi pi-download"
            className="p-button-text"
            onClick={exportSummaryLogs}
            style={{float: "right"}}
           />
          <Button
            icon="pi pi-refresh"
            className="p-button-text"
            onClick={fetchSummaryLogs}
            style={{ float: "right" }}
          />
          
        </div>
      </div>
      <DataTable
        value={summaryLogs}
        ref={logsTableRef}
        filters={tableFilters}
        filterDisplay="menu"
        sortField="receivedDate"
        sortOrder={-1}
        paginator
        paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
        currentPageReportTemplate="Items per page"
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        emptyMessage="No logs found."
      >
        <Column header="Process Type" field="usecaseType" sortable filter />
        <Column header="RITM" field="ctaskId" sortable filter />
        <Column
          header="Received At"
          field="receivedDate"
          body={receivedAtTemplate}
          sortable
        />
        <Column header="Status" field="status" sortable filter />
        <Column
          header="Schedule At"
          field="scheduledTime"
          body={scheduledAtTemplate}
          sortable
        />
        <Column header="Retry Count" field="pushRetryCount" sortable filter />
        <Column
          header="Modified At"
          field="modifiedDate"
          body={modifiedAtTemplate}
          sortable
        />
        <Column
          header="Input Payload"
          field="inputPayload"
          body={inputPayloadTemplate}
        />
        <Column header="Requester" field="ctaskRequester" sortable filter />
        <Column header="Details" body={detailTemplate} />
      </DataTable>
    </div>
  );
};

export default AutomationLogs;
