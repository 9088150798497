import React, {useState, useEffect, useRef} from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterOperator, FilterMatchMode } from 'primereact/api';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { addDateTime, parseDateTime } from '../../../../common/date-time';
import { useSelector, useDispatch } from 'react-redux';
import Moment from 'moment';

import { getAlertSuppressionLogsInit, deleteAlertSuppressionRequestInit } from '../redux/actions';
import ReseAlertSuppressionLogsDialog from './ReseAlertSuppressionLogsDialog';
import './ReseAlertSuppressionLogs.scss';

const Footer = (accept, reject, rowData) => {
    return (
    <div className="d-flex justify-content-end mr-2 mb-2">
        <Button label="Complete" className="p-button-sm mr-2" onClick={reject} />
        <Button label="Cancel" className="p-button-sm" disabled={!rowData.is_active} onClick={accept} />
    </div>
    );
};

const ReseAlertSuppressionLogs = () => {
    const [logsRange, setLogsRange] = useState([addDateTime("day", -14),addDateTime("day", 0)]);
    const [dialogData, setDialogData] = useState(null);
    const [tableFilters, setTableFilters] = useState(null);

    const alertSuppressionLogs = useSelector(state => state.reseAlertSuppression.alertSuppressionLogs);
    const dispatch = useDispatch();
    const alertSuppressonLogsRef = useRef(null);

    const userRoles = useSelector(state => state.profile.userRoles);
    const reseadmin = userRoles.includes("ReseAlertSuppression.Write");

    const startDatetime = Moment(logsRange[0]).format("yyyy-MM-DD");
    const endDatetime = !!logsRange[1]
    ? Moment(logsRange[1]).format("yyyy-MM-DD")
    : Moment(logsRange[0]).format("yyyy-MM-DD");
    const offset = Moment(new Date()).utcOffset();

    useEffect(() => {
        fetchAlertSuppressionLogs();
        initializeTableFilter();
    }, []);

    const initializeTableFilter = () => {
        setTableFilters({
            global: {value: null, matchMode: FilterMatchMode.CONTAINS},
            store_id: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              },
              asset_id: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              },
              alert_name: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              },
              point_id: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              },
              is_store_specific: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              },
              is_asset_specific: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              },
              is_alert_name_specific: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              },
              is_point_id_specific: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
            },
            is_active: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
            },
            reason: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
            },
            notes: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
            },

        });
    }

    const fetchAlertSuppressionLogs = () => {
        const startDatetime = Moment(logsRange[0]).format("yyyy-MM-DD");
        const endDatetime = !!logsRange[1]
        ? Moment(logsRange[1]).format("yyyy-MM-DD")
        : Moment(logsRange[0]).format("yyyy-MM-DD");
        const offset = Moment(new Date()).utcOffset();
        dispatch(getAlertSuppressionLogsInit(startDatetime, endDatetime, offset));
    }

    const deleteSuppressionRule = (rowData) => {
        const workStatus = "Deleted";
        dispatch(deleteAlertSuppressionRequestInit(rowData.suppression_id, workStatus,
        startDatetime, endDatetime, offset));
    }

    const completeSuppressionRule = (rowData) => {
        const workStatus = "Completed";
        dispatch(deleteAlertSuppressionRequestInit(rowData.suppression_id, workStatus,
        startDatetime, endDatetime, offset));
    }


    /*
    Data handling functions
    */
    const suppressionFromTemplate = ({suppression_from}) => parseDateTime(suppression_from);
    const suppressionTillTemplate = ({suppression_till}) => parseDateTime(suppression_till);

    const actionsTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                icon="pi pi-info-circle"
                className="p-button-text"
                onClick={() => setDialogData(rowData)}
                />
                <Button
                icon="pi pi-times-circle"
                className="p-button-text p-button-danger"
                disabled={!reseadmin}
                onClick={(event) => confirmPopup({
                    target: event.currentTarget,
                    message: "Are you sure you want to cancel this alert suppression rule?",
                    icon: "pi pi-exclamation-triangle",
                    accept: () => deleteSuppressionRule(rowData),
                    reject: () => completeSuppressionRule(rowData),
                    footer: (options) => Footer(options.accept, options.reject, rowData)
                })}
                />
            </div>
        );
    }
    return (
        <div className="rese-alert-suppression-logs-container container-fluid">
            <ReseAlertSuppressionLogsDialog dialogData={dialogData} setDialogData={setDialogData} />
            <p className="page-label">
                RESE
                <i className="pi pi-angle-right"/>
                Alert Suppression Logs
            </p>
            <div className="logs-container-top">
            <div className="row">
                <div className="col-12 col-lg-6 input-field">
                    <label>Logs Range</label>
                    <Calendar
                    name="logsRange"
                    value={logsRange}
                    onChange={(e) => setLogsRange(e.value)}
                    selectionMode="range"
                    // minDate={addDateTime("month", -1)}
                    maxDate={addDateTime("month", 0)}
                    showIcon
                  />
                </div>
            </div>
            <div className="row get-logs-buttons">
                <div className="col-12">
                    <Button label="Get Logs"
                    className="p-button-primary"
                    onClick={fetchAlertSuppressionLogs}
                    />
                    <Button
                    icon="pi pi-download"
                    className="p-button-text"
                    onClick={() => alertSuppressonLogsRef.current.exportCSV()}
                    style={{float: "right"}}
                    />
                    <Button
                    icon="pi pi-refresh"
                    className="p-button-text"
                    onClick={fetchAlertSuppressionLogs}
                    style={{float: "right"}}
                    />
                </div>
            </div>
            </div>
            <ConfirmPopup />
            <DataTable
            value={alertSuppressionLogs}
            ref={alertSuppressonLogsRef}
            className="p-datatable-gridlines custom-datatable"
            filters={tableFilters}
            filterDisplay='menu'
            sortField='created_timestamp'
            sortOrder={-1}
            paginator
            paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="Items per page"
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            emptyMessage="No logs found."
            >
                <Column header="Actions" body={actionsTemplate} />
                <Column field="store_id" header="Store ID" sortable filter />
                <Column field="asset_id" header="Asset ID" sortable filter />
                <Column field="alert_name" header="Alert Name" sortable filter />
                <Column field="point_id" header="Point ID" sortable filter />
                <Column field="is_store_specific" header="Store ID Specific" sortable filter />
                <Column field="is_asset_specific" header="Asset ID Specific" sortable filter />
                <Column field="is_alert_name_specific" header="Alert Name Specific" sortable filter />
                <Column field="is_point_id_specific" header="Point ID Specific" sortable filter />
                <Column field="suppression_from" header="Suppression From" body={suppressionFromTemplate} sortable />
                <Column field="suppression_till" header="Suppression Till" body={suppressionTillTemplate} sortable />
                <Column field="is_active" header="Is Active" sortable filter />
                <Column field="reason" header="Reason" sortable filter />
                <Column field="notes" header="Notes" sortable filter />
            </DataTable>
        </div>
    );
}
export default ReseAlertSuppressionLogs;