import React, { useState, useEffect, useMemo } from 'react';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useLocation, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { getAlertSuppressionReasonsInit, postAlertSuppressionInformationInit } from '../redux/actions';
import { addDateTime } from '../../../../common/date-time';
import "./ReseAlertSuppressionRequest.scss";

const ReseAlertSuppressionRequest = () => {

    const [alertInfo, setAlertInfo] = useState({
        storeId: '',
        assetId: '',
        alertName: '',
        pointId: ''
    });
    const [alertInfoEroor, setAlertInfoError] = useState(false);
    const [suppressionConfirmDialog, setSuppressionConfirmDialog] = useState(false);
    const initialSuppressionFrom = useMemo(() => addDateTime("minutes", 15, new Date()), []);
    const initialSuppressionTill = useMemo(() => addDateTime("day", 1, initialSuppressionFrom), []);

    const alertSuppressionReasons = useSelector(state => state.reseAlertSuppression.alertSuppressionReasons);
    const dispatch = useDispatch();
    const history = useHistory();

    const userRoles = useSelector(state => state.profile.userRoles);
    const superAdmin = userRoles.includes("ReseAlertSuppression.Superadmin");

    const defaultState = {
        storeId: alertInfo.storeId,
        assetId: alertInfo.assetId,
        alertName: alertInfo.alertName,
        pointId: alertInfo.pointId,
        isStoreSpecific: true,
        isAssetSpecific: true,
        isAlertNameSpecific: true,
        isPointIdSpecific: true,
        reason: '',
        notes: '',
        suppressionFrom: initialSuppressionFrom,
        suppressionTill: initialSuppressionTill
    };

    const {
        values,
        touched,
        handleChange,
        setFieldValue,
        handleSubmit,
        errors,
        resetForm
    } = useFormik({
        enableReinitialize: true,
        initialValues: defaultState,
        validate: (values) => {
            let errors = {};
            validateReason(values, errors);
            validateNotes(values, errors);
            validateSuppressionFrom(values, errors);
            validateSuppressionTill(values, errors);
            return errors;
        },
        onSubmit: (values) => {
            setSuppressionConfirmDialog(true);
        }
    });

    const submitHandler = () => {
        if(!alertInfo.storeId || alertInfo.storeId === "N/A" || !alertInfo.assetId || alertInfo.assetId === "N/A" ||
            !alertInfo.alertName || alertInfo.alertName === "N/A" || !alertInfo.pointId || alertInfo.pointId === "N/A") {
            setAlertInfoError(true);
            return;
        }
        const payload = {
            store_id: values.storeId,
            asset_id: values.assetId,
            alert_name: values.alertName,
            point_id: values.pointId,
            is_store_specific: values.isStoreSpecific,
            is_asset_specific: values.isAssetSpecific,
            is_alert_name_specific: values.isAlertNameSpecific,
            is_point_id_specific: values.isPointIdSpecific,
            suppression_till: values.suppressionTill,
            suppression_from: values.suppressionFrom,
            reason: values.reason,
            notes: values.notes,
            work_status: null
        }
        dispatch(postAlertSuppressionInformationInit(payload));
        clearForm();
        setTimeout(() => {
            history.push('/admin/rese/alert-suppression-logs');
        }, 3000);
    }

    const rejectHandler = () => {
        setSuppressionConfirmDialog(false);
    }

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const query = useQuery();

    useEffect(() => {
        const params = {
            storeId: query.get('storeId') || "N/A",
            assetId: query.get('assetId') || "N/A",
            alertName: query.get('alertName') || "N/A",
            pointId: query.get('pointId') || "N/A"
        };
        setAlertInfo(params);
    }, []);

    useEffect(() => {
        dispatch(getAlertSuppressionReasonsInit());
    },[])

    const clearForm = () =>     {
        resetForm();
    }

    const validateReason = (values, errors) => {
        if (!values.reason) {
            errors.reason = "Please select the reason";
        }
    }

    const validateNotes = (values, errors) => {
        if (!values.notes) {
            errors.notes = "Please enter the notes";
        }
        else if (values.notes.length > 150) {
            errors.notes = "notes should not be more than 150 characters";
        }
    }

    const validateSuppressionFrom = (values, errors) => {
        if (values.suppressionFrom <= addDateTime("minutes", 14, new Date())) {
            errors.suppressionFrom = "Suppression start datetime should be atleast 15 mins from current datetime";
        }
    }

    const validateSuppressionTill = (values, errors) => {
        if (values.suppressionTill < addDateTime("minutes", 15, values.suppressionFrom)) {
            errors.suppressionTill = "Suppression end datetime should be atleast 15 mins from suppression start datetime";
        }
    }

    const messageTemplate = () => {
        return (
            <div className="confirmDialog-content">
                <div>
                <p>Suppress the Alert named <strong>{values.alertName} </strong> For : </p>
                </div>
                {!!values.isStoreSpecific && <div className='row input-field'>
                    <div className='col-12 col-lg-5'>
                        <label>Store ID</label>
                    </div>
                    <div className='col-12 col-lg-7'>
                        <strong>{values.storeId}</strong>
                    </div>
                </div>}
                {!!values.isAssetSpecific && <div className='row input-field'>
                    <div className='col-12 col-lg-5'>
                        <label>Asset ID</label>
                    </div>
                    <div className='col-12 col-lg-7'>
                        <strong>{values.assetId}</strong>
                    </div>
                </div>}
                {!!values.isPointIdSpecific && <div className='row input-field'>
                    <div className='col-12 col-lg-5'>
                        <label>Point ID</label>
                    </div>
                    <div className='col-12 col-lg-7'>
                        <strong>{values.pointId}</strong>
                    </div>
                </div>}
                {!!values.isAlertNameSpecific && <div className='row input-field'>
                    <div className='col-12 col-lg-5'>
                        <label>Alert Name</label>
                    </div>
                    <div className='col-12 col-lg-7'>
                        <strong>{values.alertName}</strong>
                    </div>
                </div>}
            </div>
        )
    }

    return (
        <div className="rese-alert-suppression-request-container container-fluid">
            <p className="page-label">
                RESE
                <i className="pi pi-angle-right"/>
                Alert Suppression Request
            </p>
            <p className="alert-info-text">Alert Information</p>
            <div className="row alert-configuraion-cards">
                <Card className="custom-card" title="Store ID" subTitle={<span className="card-subtitle" title={alertInfo.storeId}>{alertInfo.storeId}</span>}/>
                <Card className="custom-card" title="Asset ID" subTitle={<span className="card-subtitle" title={alertInfo.assetId}>{alertInfo.assetId}</span>}/>
                <Card className="custom-card" title="Alert Name" subTitle={<span className="card-subtitle" title={alertInfo.alertName}>{alertInfo.alertName}</span>}/>
                <Card className="custom-card" title="Point ID" subTitle={<span className="card-subtitle" title={alertInfo.pointId}>{alertInfo.pointId}</span>}/>
            </div>
            {!!alertInfoEroor && <p className="p-error alert-info-error-text">Alert information is required to submit the suppression request</p>}
            <Divider type="solid"/>
            <p className="alert-info-text mt-4">Alert Configuration</p>
            <form className="container-fluid" onSubmit={handleSubmit}>
                <ConfirmDialog group="declarative" style={{width: "40%"}} visible={suppressionConfirmDialog} onHide={() =>setSuppressionConfirmDialog(false)}
                    message={messageTemplate}
                    header="Suppression Rule Confirmation" accept={submitHandler} reject={rejectHandler}/>
            <div className="row">
                <div className="col-6 border-right">
                    <div className="row input-field">
                        <div className="col-12 col-lg-4">
                            <label>Store ID</label>
                        </div>
                        <div className="col-12 col-lg-8">
                            <InputSwitch 
                            name="storeSwitch"
                            checked={values.isStoreSpecific}
                            onChange={(e) => {
                                setFieldValue("isStoreSpecific", e.value)
                            }}
                            disabled={!superAdmin}
                            />
                        </div>
                    </div>
                    <div className="row input-field">
                        <div className="col-12 col-lg-4">
                            <label>Alert Name</label>
                        </div>
                        <div className="col-12 col-lg-8">
                            <InputSwitch
                            name="alertSwitch"
                            checked={values.isAlertNameSpecific}
                            onChange={(e) => {
                                setFieldValue("isAlertNameSpecific", e.value)
                            }}
                            disabled={!superAdmin}
                            />
                        </div>
                    </div>
                    <div className="row input-field">
                        <div className="col-12 col-lg-4">
                            <label>Reason</label>
                        </div>
                        <div className="col-12 col-lg-8">
                            <span>
                            <Dropdown
                            name="reason"
                            options={alertSuppressionReasons}
                            optionLabel="alert_suppression_reason"
                            optionValue="alert_suppression_reason"
                            value={values.reason}
                            onChange={handleChange}
                            placeholder="Select Reason"/>
                            <br/>
                            {!!touched.reason && errors.reason &&<span className="p-error">{errors.reason}</span>}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-6 content-padding">
                    <div className="row input-field">
                        <div className="col-12 col-lg-4">
                            <label>Asset ID</label>
                        </div>
                        <div className="col-12 col-lg-8">
                            <InputSwitch name="assetSwitch"
                            checked={values.isAssetSpecific}
                            onChange={(e) => {
                                setFieldValue("isAssetSpecific", e.value)
                            }}
                            />
                        </div>
                    </div>
                    <div className="row input-field">
                        <div className="col-12 col-lg-4">
                            <label>Point ID</label>
                        </div>
                        <div className="col-12 col-lg-8">
                            <InputSwitch
                            name="pointSwitch"
                            checked={values.isPointIdSpecific}
                            onChange={(e) => {
                                setFieldValue("isPointIdSpecific", e.value)
                            }}
                            />
                        </div>
                    </div>
                    <div className="row input-field">
                        <div className="col-12 col-lg-4">
                            <label>Notes</label>
                        </div>
                        <div className="col-12 col-lg-8">
                            <span>
                            <InputText name="notes" value={values.notes} onChange={handleChange}/>
                            <br/>
                            {!!touched.notes && errors.notes && <span className="p-error">{errors.notes}</span>}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
                <div className="row mt-4">
                <div className="col-6">
                    <div className="row input-field">
                        <div className="col-12 col-lg-4">
                            <label>Suppression From</label>
                        </div>
                        <div className="col-12 col-lg-8">
                            <span>
                            <Calendar
                            name="suppressionFrom"
                            value={values.suppressionFrom}
                            onChange={(e) => setFieldValue("suppressionFrom", e.value)}
                            // minDate={addDateTime("minutes", 15, new Date())}
                            showTime
                            showIcon
                            />
                            <br/>
                            {!!touched.suppressionFrom && errors.suppressionFrom && <span className="p-error">{errors.suppressionFrom}</span>}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-6 content-padding">
                    <div className="row input-field">
                        <div className="col-12 col-lg-4">
                            <label>Suppression Till</label>
                        </div>
                        <div className="col-12 col-lg-8">
                            <span>
                            <Calendar
                            name="suppressionTill"
                            value={values.suppressionTill}
                            onChange={(e) => setFieldValue("suppressionTill", e.value)}
                            // minDate={addDateTime("minutes", 15, values.suppressionFrom)}
                            showTime
                            showIcon
                            />
                            <br/>
                            {!!touched.suppressionTill && errors.suppressionTill && <span className="p-error">{errors.suppressionTill}</span>}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4 input-field">
                <Button label="Submit" type="submit"/>
                <Button label="Clear" type="reset" onClick={clearForm} className="p-button-secondary"/>
            </div>
        </form>

        </div>
    );
}
export default ReseAlertSuppressionRequest;
