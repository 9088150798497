export const GET_ALERT_SUPPRESSION_REASONS = {
    INIT: "GET_ALERT_SUPPRESSION_REASONS_INIT",
    SUCCESS: "GET_ALERT_SUPPRESSION_REASONS_SUCCESS",
    ERROR: "GET_ALERT_SUPPRESSION_REASONS_ERROR"
}

export const POST_ALERT_SUPPRESSION_INFORMATION = {
    INIT: "POST_ALERT_SUPPRESSION_INFORMATION_INIT",
    SUCCESS: "POST_ALERT_SUPPRESSION_INFORMATION_SUCCESS",
    ERROR: "POST_ALERT_SUPPRESSION_INFORMATION_ERROR"
}

export const GET_ALERT_SUPPRESSION_LOGS = {
    INIT: "GET_ALERT_SUPPRESSION_LOGS_INIT",
    SUCCESS: "GET_ALERT_SUPPRESSION_LOGS_SUCCESS",
    ERROR: "GET_ALERT_SUPPRESSION_LOGS_ERROR"
}

export const DELETE_ALERT_SUPPRESSION_REQUEST = {
    INIT: "DELETE_SUPPRESSION_REQUEST_INIT",
    SUCCESS: "DELETE_SUPPRESSION_REQUEST_SUCCESS",
    ERROR: "DELETE_SUPPRESSION_REQUEST_ERROR"
}