import {
    GET_CC_RIT_USECASES,
    GET_CC_RIT_USECASES_LOGS,
    GET_CC_RIT_USECASES_DETAILS,
    GET_CC_INCIDENT_USECASES,
    GET_CC_INCIDENT_USECASES_LOGS,
    GET_CC_INCIDENT_USECASES_DETAILS,
} from "./actionTypes";

const initialState = {
    ritUsecases: [],
    ritUsecasesErrorMessage: null,
    ritUsecasesLogs: [],
    ritUsecasesLogsErrorMessage: null,
    ritUsecasesDetails: [],
    ritUsecasesDetailsErrorMessage: null,
    incidentUsecases: [],
    incidentUsecasesErrorMessage: null,
    incidentUsecasesLogs: [],
    incidentUsecasesLogsErrorMessage: null,
    incidentUsecasesDetails: [],
    incidentUsecasesDetailsErrorMessage: null,
    totalRecords:0,
    totalRecord:0,
};

const commandCenterReducer = (
    state = initialState,
    action = { type: "", payload: "" }
) => {
    switch (action.type) {
        case GET_CC_RIT_USECASES.SUCCESS:
            return {
                ...state,
                ritUsecases: action.payload,
                ritUsecasesErrorMessage: null,
            };
        case GET_CC_RIT_USECASES.ERROR:
            return {
                ...state,
                ritUsecases: [],
                ritUsecasesErrorMessage: action.payload,
            };
        case GET_CC_RIT_USECASES_LOGS.SUCCESS:
            let { eventsList, totalRecords } = action.payload;
            return {
                ...state,
                ritUsecasesLogs: eventsList, 
                totalRecords:totalRecords,
                ritUsecasesLogsErrorMessage: null,
            };
        case GET_CC_RIT_USECASES_LOGS.ERROR:
            return {
                ...state,
                ritUsecasesLogs: [],totalRecords: 0,
                ritUsecasesLogsErrorMessage: action.payload,
            };
        case GET_CC_RIT_USECASES_DETAILS.SUCCESS:
            return {
                ...state,
                ritUsecasesDetails: action.payload,
                ritUsecasesDetailsErrorMessage: null,
            };
        case GET_CC_RIT_USECASES_DETAILS.ERROR:
            return {
                ...state,
                ritUsecasesDetails: [],
                ritUsecasesDetailsErrorMessage: action.payload,
            };
        case GET_CC_INCIDENT_USECASES.SUCCESS:
            return {
                ...state,
                incidentUsecases: action.payload,
                incidentUsecasesErrorMessage: null,
            };
        case GET_CC_INCIDENT_USECASES.ERROR:
            return {
                ...state,
                incidentUsecases: [],
                incidentUsecasesErrorMessage: action.payload,
            };
        case GET_CC_INCIDENT_USECASES_LOGS.SUCCESS:
            let { rpaDetailChange, totalRecord } = action.payload;
            return {
                ...state,
                incidentUsecasesLogs: rpaDetailChange,
                totalRecord: totalRecord,
                incidentUsecasesLogsErrorMessage: null,
            };
        case GET_CC_INCIDENT_USECASES_LOGS.ERROR:
            return {
                ...state,
                incidentUsecasesLogs: [],totalRecord: 0,
                incidentUsecasesLogsErrorMessage: action.payload,
            };
        case GET_CC_INCIDENT_USECASES_DETAILS.SUCCESS:
            return {
                ...state,
                incidentUsecasesDetails: action.payload,
                incidentUsecasesDetailsErrorMessage: null,
            };
        case GET_CC_INCIDENT_USECASES_DETAILS.ERROR:
            return {
                ...state,
                incidentUsecasesDetails: [],
                incidentUsecasesDetailsErrorMessage: action.payload,
            };
        default:
            return { ...state };
    }
};

export default commandCenterReducer;
