import { GET_ALERT_SUPPRESSION_REASONS,
POST_ALERT_SUPPRESSION_INFORMATION,
GET_ALERT_SUPPRESSION_LOGS,
DELETE_ALERT_SUPPRESSION_REQUEST } from "./actionTypes";

export const getAlertSuppressionReasonsInit = () => ({
    type: GET_ALERT_SUPPRESSION_REASONS.INIT
});

export const getAlertSuppressionReasonsSuccess = (reasons) => ({
    type: GET_ALERT_SUPPRESSION_REASONS.SUCCESS,
    payload: reasons
});

export const getAlertSuppressionReasonsError = (error) => ({
    type: GET_ALERT_SUPPRESSION_REASONS.ERROR,
    payload: error
});

export const postAlertSuppressionInformationInit = (alertSuppressionInfo) => ({
    type: POST_ALERT_SUPPRESSION_INFORMATION.INIT,
    alertSuppressionInfo
});

export const postAlertSuppressionInformationSuccess = (alertSuppressionInfoSuccessMessage) => ({
    type: POST_ALERT_SUPPRESSION_INFORMATION.SUCCESS,
    payload:alertSuppressionInfoSuccessMessage
});

export const postAlertSuppressionInformationError = (error) => ({
    type: POST_ALERT_SUPPRESSION_INFORMATION.ERROR,
    payload: error
});

export const getAlertSuppressionLogsInit = (startDatetime, endDatetime, offset) => ({
    type: GET_ALERT_SUPPRESSION_LOGS.INIT,
    startDatetime,endDatetime,offset
});

export const getAlertSuppressionLogsSuccess = (alertSuppressionLogs) => ({
    type: GET_ALERT_SUPPRESSION_LOGS.SUCCESS,
    payload: alertSuppressionLogs
});

export const getAlertSuppressionLogsError = (error) => ({
    type: GET_ALERT_SUPPRESSION_LOGS.ERROR,
    payload: error
});

export const deleteAlertSuppressionRequestInit = (suppressionId, workStatus, startDatetime, endDatetime, offset ) => ({
    type: DELETE_ALERT_SUPPRESSION_REQUEST.INIT,
    suppressionId, workStatus, startDatetime, endDatetime, offset
});

export const deleteAlertSuppressionRequestSuccess = (deleteAlertSuppressionRequestSuccessMessage) => ({
    type: DELETE_ALERT_SUPPRESSION_REQUEST.SUCCESS,
    payload: deleteAlertSuppressionRequestSuccessMessage
});

export const deleteAlertSuppressionRequestError = (error) => ({
    type: DELETE_ALERT_SUPPRESSION_REQUEST.ERROR,
    payload: error
});