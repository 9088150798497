import { call, put } from "redux-saga/effects";
import { getAlertSuppressionReasonsSuccess,
getAlertSuppressionReasonsError,
postAlertSuppressionInformationSuccess,
postAlertSuppressionInformationError,
getAlertSuppressionLogsSuccess,
getAlertSuppressionLogsError,
deleteAlertSuppressionRequestSuccess,
deleteAlertSuppressionRequestError } from "../components/rese/alert-suppression/redux/actions";
import  { getAlertSuppressionReasons, postAlertSuppressionInformation, getAlertSuppressionLogs, deleteAlertSuppressionRequest } from "../components/rese/alert-suppression/ReseAlertSuppressionService";

export function* loadAlertSuppressionReasons () {
    try {
        const reasons = yield call(getAlertSuppressionReasons);
        yield put(getAlertSuppressionReasonsSuccess(reasons));
    } catch (error) {
        yield put(getAlertSuppressionReasonsError(error.toString()));
    }
}

export function* saveAlertSuppressionInformation ({alertSuppressionInfo}) {
    try {
        const alertSuppressionInfoSuccessMessage = yield call(postAlertSuppressionInformation, alertSuppressionInfo);
        yield put(postAlertSuppressionInformationSuccess(alertSuppressionInfoSuccessMessage));
    } catch (error) {
        yield put(postAlertSuppressionInformationError(error.toString()));
    }
}

export function* loadAlertSuppressionLogs ({startDatetime, endDatetime, offset}) {
    try {
        const alertSuppressionlogs = yield call(getAlertSuppressionLogs, startDatetime, endDatetime, offset);
        yield put(getAlertSuppressionLogsSuccess(alertSuppressionlogs));
    } catch (error) {
        yield put(getAlertSuppressionLogsError(error.toString()));
    }
}

export function* deleteAlertSuppressionRule ({suppressionId, workStatus, startDatetime, endDatetime, offset}) {
    try {
        const deleteAlertSuppressionRequestSuccessMessage = yield call(deleteAlertSuppressionRequest, suppressionId, workStatus);
        yield put(deleteAlertSuppressionRequestSuccess(deleteAlertSuppressionRequestSuccessMessage));
        try {
            const alertSuppressionlogs = yield call(getAlertSuppressionLogs, startDatetime, endDatetime, offset);
            yield put(getAlertSuppressionLogsSuccess(alertSuppressionlogs));
        } catch (error) {
            yield put(getAlertSuppressionLogsError(error.toString()));
        }
    } catch (error) {
        yield put(deleteAlertSuppressionRequestError(error.toString()));
    }
}