import { call, put } from "redux-saga/effects";

import {
    getDistinctPingprobeAssetTypeSuccess,
    getDistinctPingprobeAssetTypeFailure,
    getPingprobeInventorySuccess,
    getPingprobeInventoryFailure,
    getPingprobeIncidentByAssetSuccess,
    getPingprobeIncidentByAssetFailure,
    getPingprobeAssetLastPingTimeSuccess,
    getPingprobeAssetLastPingTimeFailure,
    postPingprobeSuppressionRuleSuccess,
    postPingprobeSuppressionRuleFailure,
    getAllPingprobeSuppressionRulesSuccess,
    getAllPingprobeSuppressionRulesFailure,
    getPingprobeAssetsBySuppressionRuleSuccess,
    getPingprobeAssetsBySuppressionRuleFailure,
    deletePingprobeSuppressionRuleSuccess,
    deletePingprobeSuppressionRuleFailure,
    postPingprobeLivePingSuccess,
    postPingprobeLivePingFailure,
    getLivePingDetailsSuccess,
    getLivePingDetailsFailure,

} from "../components/pingprobe/redux/actions";
import {
    getDistinctPingprobeAssetType,
    getPingprobeInventory,
    getPingprobeIncidentByAsset,
    getPingprobeAssetLastPingTime,
    postPingprobeSuppressionRule,
    getAllPingprobeSuppressionRules,
    getPingprobeAssetsBySuppressionRule,
    deletePingprobeSuppressionRule,
    postPingprobeLivePing,
    getPingDetails,
} from "../components/pingprobe/PingprobeService";

export function* loadDistinctPingprobeAssetType() {
    try {
        const assetTypes = yield call(getDistinctPingprobeAssetType);
        yield put(getDistinctPingprobeAssetTypeSuccess(assetTypes));
    } catch (error) {
        yield put(getDistinctPingprobeAssetTypeFailure(error.toString()));
    }
}

export function* loadPingprobeInventory({ 
    assetTypes,
    pageNumber, 
    pageSize,
    sortBy ,               // Default sorting field
    sortDirection ,   
    filterCondition }) {
    try {
        const assetList = yield call(getPingprobeInventory, 
            assetTypes,
            pageNumber, 
            pageSize,
            sortBy ,               // Default sorting field
            sortDirection ,              // Default sort direction
            filterCondition);
        yield put(getPingprobeInventorySuccess(assetList));
    } catch (error) {
        yield put(getPingprobeInventoryFailure(error.toString()));
    }
}

export function* loadPingprobeIncidentByAsset({ ipAddress }) {
    try {
        const incidentList = yield call(getPingprobeIncidentByAsset, ipAddress);
        yield put(getPingprobeIncidentByAssetSuccess(incidentList));
    } catch (error) {
        yield put(getPingprobeIncidentByAssetFailure(error.toString()));
    }
}

export function* loadPingprobeAssetLastPingTime() {
    try {
        const pingTime = yield call(getPingprobeAssetLastPingTime);
        yield put(getPingprobeAssetLastPingTimeSuccess(pingTime));
    } catch (error) {
        yield put(getPingprobeAssetLastPingTimeFailure(error.toString()));
    }
}

export function* savePingprobeSuppressionRule({ suppressionRule }) {
    try {
        yield call(postPingprobeSuppressionRule, suppressionRule);
        yield put(postPingprobeSuppressionRuleSuccess());
        try {
            const suppressionRules = yield call(getAllPingprobeSuppressionRules);
            yield put(getAllPingprobeSuppressionRulesSuccess(suppressionRules));
        } catch (error) {
            yield put(getAllPingprobeSuppressionRulesFailure(error.toString()));
        }
    } catch (error) {
        yield put(postPingprobeSuppressionRuleFailure(error.toString()));
    }
}

export function* loadAllPingprobeSuppressionRules() {
    try {
        const suppressionRules = yield call(getAllPingprobeSuppressionRules);
        yield put(getAllPingprobeSuppressionRulesSuccess(suppressionRules));
    } catch (error) {
        yield put(getAllPingprobeSuppressionRulesFailure(error.toString()));
    }
}

export function* loadPingprobeAssetsBySuppressionRule({ suppressionRule }) {
    try {
        const assetList = yield call(
            getPingprobeAssetsBySuppressionRule,
            suppressionRule
        );
        yield put(getPingprobeAssetsBySuppressionRuleSuccess(assetList));
    } catch (error) {
        yield put(getPingprobeAssetsBySuppressionRuleFailure(error.toString()));
    }
}

export function* removePingprobeSuppressionRule({ suppressionId }) {
    try {
        yield call(deletePingprobeSuppressionRule, suppressionId);
        yield put(deletePingprobeSuppressionRuleSuccess());
        try {
            const suppressionRules = yield call(
                getAllPingprobeSuppressionRules
            );
            yield put(getAllPingprobeSuppressionRulesSuccess(suppressionRules));
        } catch (error) {
            yield put(getAllPingprobeSuppressionRulesFailure(error.toString()));
        }
    } catch (error) {
        yield put(deletePingprobeSuppressionRuleFailure(error.toString()));
    }
}

export function* postCreatePingprobeLivePing({ ipAddress }) {
    try {
        yield call(postPingprobeLivePing, ipAddress);
        yield put(postPingprobeLivePingSuccess());
    } catch (error) {
        yield put(postPingprobeLivePingFailure(error.toString()));
    }
}
export function* loadPingDetails({startDate, endDate, offset}) {
    try {
        const pingDetails = yield call(getPingDetails, startDate, endDate, offset);
        yield put(getLivePingDetailsSuccess(pingDetails));
    }
    catch(error) {
        yield put(getLivePingDetailsFailure(error.toString()));
    }
}
