import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tooltip } from "primereact/tooltip";
import Moment from "moment";

import { parseDateTime, addDateTime } from "../../../../common/date-time";
import { getCCRITUsecasesLogsInit } from "../../redux/actions";
import {
    alertPayloadTemplate,
    commonTableFilters,
    modifiedAtTemplate,
    receivedAtTemplate,
} from "../utils";
import DetailSummaryDialog from "./DetailSummaryDialog";
import "./RitSummary.scss";

const RitSummary = () => {
    const ritLogs = useSelector((state) => state.commandCenter.ritUsecasesLogs);
    const totalRecords = useSelector(state => state.commandCenter.totalRecords); // Redux total records
    const dispatch = useDispatch();
    const [dateRange, setDateRange] = useState([
        addDateTime("day", -14),
        addDateTime("day", 0),
    ]);
    const [tableFilters, setTableFilters] = useState(null);
    const [dialogData, setDialogData] = useState(null);
    const logsTableRef = useRef(null);
    const [filterCondition, setFilterCondition] = useState(""); // **Added filterCondition state**

    const [lazyParams, setLazyParams] = useState({
        first: 0, // Starting index
        rows: 10, // Default page size
        page: 0, // Current page number
        sortField: "received_date", // Default sort field
        sortOrder: -1, // Default sort order (-1 for descending, 1 for ascending)
    });


    useEffect(() => {
        initializeTableFilter();
        fetchLogs();
    }, []);

    const initializeTableFilter = () => {
        setTableFilters({
            ...commonTableFilters,
            request_id: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
        });
    };


        const fetchLogs = (params=lazyParams,currentFilters=filterCondition) => {
            const sortDirection= params.sortOrder== -1?'DESC' : 'ASC';
            const startDate = Moment(dateRange[0]).format("yyyy-MM-DD");
            const endDate = !!dateRange[1]
                ? Moment(dateRange[1]).format("yyyy-MM-DD")
                : Moment(dateRange[0]).format("yyyy-MM-DD");
            const offset = Moment(new Date()).utcOffset();
            const filterConditionPayload = constructFilterParams(currentFilters);
            const requestParams={
                startDate,
                endDate,
                offset,
                pageNumber: params.page ?? 0, // Current page number
                pageSize: params.rows ?? 10, // Number of rows per page
                sortBy: params.sortField ?? "received_date", // Sorting field
                sortDirection: sortDirection ?? "DESC", // Sorting order
                filterCondition: filterConditionPayload ?? "" // **Filter condition**
            };
            dispatch(getCCRITUsecasesLogsInit(requestParams));
        };
    const constructFilterParams = (filterConditions) => {
        if (!filterConditions || typeof filterConditions !== "object") {
          return ""; // Return empty string if no filters are present
        }
      
        const queryParams = [];
      
        Object.keys(filterConditions).forEach((key) => {
          const filter = filterConditions[key];
          const constraints = filter?.constraints;
      
          if (constraints && constraints.length > 0) {
            const { value, matchMode } = constraints[0];
      
            if (value !== undefined && value !== null && value !== "") {
              queryParams.push(
                `${encodeURIComponent(key)}&${encodeURIComponent(matchMode)}&${encodeURIComponent(value)}`
              );
            }
          }
        });
      
        return queryParams.join(",");
      };

    const detailTemplate = ({ request_id }) => (
        <i
            className="pi pi-info-circle"
            onClick={() => setDialogData(request_id)}
        ></i>
    );

    const changeRequestTemplate =({
        isCR_applicable,
        cr_number,
        cr_status,
        cr_updated_date
    })=>{
        if (!isCR_applicable) {
            return <div>N.A.</div>;
        } 
        let icon = "";
        if (cr_status && cr_status.toLowerCase() === "approved") {
            icon = "pi-check";
        } else if (cr_status && cr_status.toLowerCase() === "rejected") {
            icon = "pi-times";
        } else {
            return <div>N.A.</div>;
        } 
        return (
            <div className="cr-container">
                <Tooltip target={`.${cr_number}`}>
                    <table>
                        <tbody>
                            <tr>
                                <td>Status:</td>
                                <td>{cr_status || "Unknown"}</td>
                            </tr>
                            <tr>
                                <td>Modified At:</td>
                                <td>{!!cr_updated_date ? parseDateTime(cr_updated_date) : "Unknown"}</td>
                            </tr>
                        </tbody>
                    </table>
                </Tooltip>
                <i className={`pi ${icon} ${cr_number}`}></i>
                {cr_number}
            </div>
        ); 
    }
 /**
   * Function to export table data
   */
 const exportRitLogs = () => logsTableRef.current.exportCSV();

 /**
     * Handle filtering event
     */
 const onFilter = (filters) => {
    console.log("filters",filters);
    setFilterCondition(filters);
    const updatedParams={
        ...lazyParams, 
          first: 0,
          page: 0
    };
    setLazyParams(updatedParams); // Reset to first page
    fetchLogs(updatedParams,filters); // Fetch filtered data
};

/**
 * Handle pagination event
 */
const onPage = (event) => {
    console.log( "pageevnt",event);
    const updatedParams=
    {
        ...lazyParams,
        first: event.first, // Start index
        rows: event.rows, // Rows per page
        page: event.page // Current page number
    };
    setLazyParams(updatedParams);
    console.log( "page",lazyParams.page, // Current page number
        lazyParams.rows, // Number of rows per page
        lazyParams.sortField)
        fetchLogs(updatedParams); // Fetch data for the new page
};

/**
 * Handle sorting event
 */
const onSort = (event) => {
   const updatedParams={
    ...lazyParams,
    sortField: event.sortField, // Sorting field
    sortOrder: event.sortOrder // Sorting order
};
    setLazyParams(updatedParams);
    fetchLogs(updatedParams); // Fetch sorted data
};


    return (
        <div className="rit-summary-container">
            <DetailSummaryDialog
                dialogData={dialogData}
                setDialogData={setDialogData}
            />
            <div className="row summary-action">
                <div className="col-12 col-lg-6 input-field">
                    <label htmlFor="dateRange">Events Date</label>
                    <Calendar
                        id="dateRange"
                        value={dateRange}
                        onChange={(e) => setDateRange(e.value)}
                        selectionMode="range"
                        minDate={addDateTime("month", -3)}
                        maxDate={addDateTime("month", 0)}
                        showIcon
                    />
                </div>
                <div className="col-12">
                    <Button label="Get Logs" onClick={fetchLogs} />
                    <Button 
                        icon="pi pi-download"
                        className="p-button-text"
                        onClick={exportRitLogs}
                        style={{float: "right"}}
                    />
                    <Button 
                        id='refreshBtn'
                        icon="pi pi-refresh"
                        className="p-button-text"
                        onClick={fetchLogs}                       
                        style={{float: "right"}}
                    />
                </div>
            </div>
            <DataTable
                value={ritLogs}
                ref={logsTableRef}
                filters={tableFilters}
                filterDisplay="menu"
                onFilter={(e) => onFilter(e.filters)} // **Handle filtering event**
                sortField={lazyParams.sortField} // Dynamic sort field
                sortOrder={lazyParams.sortOrder} // Dynamic sort order
                lazy
                paginator
                totalRecords={totalRecords} // Dynamic total records from Redux
                first={lazyParams.first} // Start index
                rows={lazyParams.rows} // Rows per page
                rowsPerPageOptions={[10, 20, 50]} // Page size options
                onPage={onPage} // Pagination handler
                onSort={onSort} // Sorting handler
                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                emptyMessage="No logs found."
            >
                <Column header="RITM" field="request_id" sortable filter />
                <Column header="Process Type" field="usecase" sortable filter />
                <Column
                    header="Received At"
                    field="received_date"
                    body={receivedAtTemplate}
                    sortable
                />
                <Column header="Status" field="status" sortable filter />
                <Column
                    header="Modified At"
                    field="modified_date"
                    body={modifiedAtTemplate}
                    sortable
                />
                <Column
                    header="Change Request"
                    field="cr_number"
                    body={changeRequestTemplate}
                    sortable
                    filter
                />
                <Column
                    header="Alert Payload"
                    field="alert_payload"
                    body={alertPayloadTemplate}
                />
                <Column header="Details" body={detailTemplate} />
            </DataTable>
        </div>
    );
};

export default RitSummary;
